import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useInitialEffect } from '../helpers/reactHelpers';
import { FetchUserDataAsyncAction } from '../store/data/users/userActions';
import { UnderConstruction } from './components/underConstruction';
import { AppRouter } from './router';

/*
 * Style.
 */

const AppContainerDiv = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 56px auto;
  grid-template-areas:
    "header"
    "content";
`;

/*
 * Component.
 */

export const App: FC = () => {
  const shouldRenderFullApp = Boolean(localStorage?.getItem('shouldRenderFullApp'));

  const dispatch = useDispatch();

  useInitialEffect(() => {
    if (shouldRenderFullApp)
      dispatch(FetchUserDataAsyncAction());
  });

  if (!shouldRenderFullApp)
    return <UnderConstruction />;

  return (
    <AppContainerDiv>
      <AppRouter />
    </AppContainerDiv>
  );
};
