import { ellipsis, transparentize } from 'polished';
import React, { FC, RefObject } from 'react';
import styled, { css } from 'styled-components';

import { ClickHandler } from '../../../helpers/reactHelpers';
import {
  fontSize, greys, primaryColor, primaryColorText, reds,
} from '../../../helpers/styleHelpers';

/*
 * Constants.
 */

export enum ButtonTypesEnum {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SECONDARY_DANGER = 'secondary-danger',
  DANGER = 'danger',
  LINK = 'link',
  CUSTOM = 'custom'
}

export enum ButtonSizesEnum {
  NORMAL = 'NORMAL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

/*
 * Props.
 */

interface ButtonProps {
  buttonRef?: RefObject<HTMLDivElement>;
  className?: string;
  type?: ButtonTypesEnum;
  size?: ButtonSizesEnum;
  isDisabled?: boolean;
  hasHover?: boolean;
  minWidth?: number;
  maxWidth?: number;
  onClick?: ClickHandler;
}

/*
 * Style.
 */

interface StyledButtonDivProps {
  $type?: ButtonTypesEnum;
  $size?: ButtonSizesEnum;
  $isDisabled?: boolean;
  $hasHover?: boolean;
  $minWidth?: number;
  $maxWidth?: number;
}

const StyledButtonDiv = styled.div<StyledButtonDivProps>`
  display: inline-block;
  cursor: pointer;
  font-size: ${fontSize.normal};
  padding: 4px 8px;
  border: 1px solid ${greys.shade60};
  border-radius: 8px;
  user-select: none;
   
  ${(p) => p.$minWidth && css`
    min-width: ${p.$minWidth}px;
  `};
  ${(p) => p.$maxWidth && css`
  ${ellipsis()};
    max-width: ${p.$maxWidth}px;
  `};


  ${(p) => p.$type && applyTypeStyles(p.$type, Boolean(p.$hasHover))}
  ${(p) => p.$size && applySizeStyles(p.$size)}
  ${(p) => p.$isDisabled && css`
    background: ${greys.shade40};
    border-color: ${greys.shade40};
    cursor: default;

    &:hover {
      background: ${greys.shade40};
    }
  `}
  ${(p) => p.$type === ButtonTypesEnum.CUSTOM && css`
    padding: 0;
  `}
`;

/*
 * Style Helpers.
 */

function applyTypeStyles(type: ButtonTypesEnum, hasHover: boolean) {
  if (type === ButtonTypesEnum.PRIMARY)
    return css`
      background: ${primaryColor};
      border-color: ${primaryColor};
      color: ${primaryColorText};

      ${hasHover && css`
        background: ${transparentize(0.1, primaryColor)};
      `}
      ${!hasHover && css`
        &:hover {
          background: ${transparentize(0.1, primaryColor)};
        }
      `} 
    `;
  if (type === ButtonTypesEnum.SECONDARY)
    return css`
      border-color: ${greys.shade60};
      color: ${greys.shade80};

      ${hasHover && css`
        background: ${transparentize(0.9, greys.shade60)};
      `}
      ${!hasHover && css`
        &:hover {
          background: ${transparentize(0.9, greys.shade60)};
        }
      `} 
    `;
  if (type === ButtonTypesEnum.SECONDARY_DANGER)
    return css`
      border-color: ${greys.shade20};
      color: ${reds.default};

      ${hasHover && css`
        background: ${transparentize(0.9, greys.shade60)};
      `}
      ${!hasHover && css`
        &:hover {
          background: ${transparentize(0.9, greys.shade60)};
        }
      `} 
    `;
  if (type === ButtonTypesEnum.DANGER)
    return css`
      border-color: ${reds.default};
      background: ${reds.default};
      color: ${greys.white};

      ${hasHover && css`
        background: ${transparentize(0.1, reds.default)};
      `}
      ${!hasHover && css`
        &:hover {
          background: ${transparentize(0.1, reds.default)};
        }
      `} 
    `;
  if (type === ButtonTypesEnum.LINK)
    return css`
      border: none;
      color: ${primaryColorText};

      ${hasHover && css`
        background: ${transparentize(0.7, greys.shade60)};
      `}
      ${!hasHover && css`
        &:hover {
          background: ${transparentize(0.7, greys.shade60)};
        }
      `} 
    `;
  if (type === ButtonTypesEnum.CUSTOM)
    return css`
      border: none;
    `;

  return '';
}

function applySizeStyles(size: ButtonSizesEnum) {
  if (size === ButtonSizesEnum.LARGE)
    return css`
      font-size: ${fontSize.large};
      padding: 12px 16px;
    `;
  if (size === ButtonSizesEnum.MEDIUM)
    return css`
      font-size: 15px;
      padding: 8px 14px;
      line-height: 20px;
    `;
  return '';
}

/*
 * Component.
 */

export const Button: FC<ButtonProps> = (props) => {
  const {
    className, children, type = ButtonTypesEnum.SECONDARY, size, isDisabled, buttonRef, hasHover, maxWidth, minWidth, onClick,
  } = props;

  return (
    <StyledButtonDiv
      ref={buttonRef}
      className={className}
      role="button"
      $type={type}
      $size={size}
      $maxWidth={maxWidth}
      $minWidth={minWidth}
      $isDisabled={isDisabled}
      $hasHover={hasHover}
      onClick={(event) => {
        if (isDisabled || !onClick)
          return;
        onClick(event);
      }}
    >
      {children}
    </StyledButtonDiv>
  );
};
