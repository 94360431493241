import React, { FC } from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter } from "react-router-dom";
import styled from 'styled-components';

import { greys } from '../helpers/styleHelpers';
import { NotificationsContainer } from './notifications/notificationsContainer';
import { CreatePageContainer } from './pages/createPageContainer';
import { DashboardPageContainer } from './pages/developer-center/dashboardPageContainer';
import { HomePage } from "./pages/homePage";
import { InvalidRoutePage } from "./pages/invalidRoutePage";
import { LoginPageContainer } from './pages/loginPageContainer';
import { GithubOAuthCallbackContainer } from './pages/oauth/githubOAuthCallbackContainer';
import { ChangePasswordPageContainer } from './pages/user/changePasswordPageContainer';
import { UserPageContainer } from './pages/user/userPageContainer';
import { ToolbarContainer } from './toolbar/toolbarContainer';

/*
 * Constants.
 */

const loginPageRoute = '/login';

/*
 * Styles.
 */

const StyledToolbarContainer = styled(ToolbarContainer)`
  grid-area: "header";
`;

const StyledContentDiv = styled.div`
  grid-area: "content";
  background-color: ${greys.white};
  width: 100%;
`;

/*
 * Component.
 */

export const AppRouter: FC = () => (
  <BrowserRouter>
    <StyledToolbarContainer />
    <StyledContentDiv>
      <Switch>
        <Route exact path="/oauth/callback/github">
          <GithubOAuthCallbackContainer />
        </Route>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/user">
          <UserPageContainer />
        </Route>
        <Route exact path="/user/change-password">
          <ChangePasswordPageContainer />
        </Route>
        <Route path={loginPageRoute}>
          <LoginPageContainer />
        </Route>
        <Route path="/create">
          <CreatePageContainer />
        </Route>
        <Route path="/dashboard">
          <DashboardPageContainer />
        </Route>
        <Route>
          <InvalidRoutePage />
        </Route>
      </Switch>
    </StyledContentDiv>
    <NotificationsContainer />
  </BrowserRouter>
);
