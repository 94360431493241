import React, { FC } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { Handler, useInitialEffect } from "../../../helpers/reactHelpers";
import { UserModel } from "../../../models/userModel";
import { DeleteUserAsyncAction, FetchUserDataAsyncAction, UpdateUserAsyncAction } from "../../../store/data/users/userActions";
import { getUser } from "../../../store/data/users/userSelectors";
import { StoreState } from "../../../store/storeState";
import { ThunkDispatch } from "../../../store/storeThunk";
import { UserPage } from "./userPage";

/*
 * Props.
 */

interface UserPageContainerStateProps {
  user?: UserModel;
}

interface UserPageContainerDispatchProps {
  fetchUserDataAsync: Handler;
  updateUserAsync: (displayName?: string) => Promise<void>;
  deleteUserAsync: () => Promise<void>;
}

type UserPageContainer = UserPageContainerStateProps & UserPageContainerDispatchProps;

/*
 * Component.
 */

export const UserPageContainerComponent: FC<UserPageContainer> = (props) => {
  const {
    user, fetchUserDataAsync, updateUserAsync, deleteUserAsync,
  } = props;
  const history = useHistory();

  useInitialEffect(() => {
    if (!user)
      history.push(`/login?next=${encodeURIComponent(history.location.pathname)}`);
    else
      fetchUserDataAsync();
  });

  if (!user)
    return null;
  return <UserPage user={user} updateUserAsync={updateUserAsync} deleteUserAsync={deleteUserAsync} />;
};

/*
 * Redux.
 */

const mapStateToProps = (state: StoreState) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  fetchUserDataAsync: () => dispatch(FetchUserDataAsyncAction()),
  updateUserAsync: (displayName?: string) => dispatch(UpdateUserAsyncAction(displayName)),
  deleteUserAsync: () => dispatch(DeleteUserAsyncAction()),
});

export const UserPageContainer = connect(mapStateToProps, mapDispatchToProps)(UserPageContainerComponent);
