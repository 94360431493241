import { ellipsis } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';

import { fontSize, greys } from '../../../helpers/styleHelpers';
import { GithubConfigModel } from '../../../models/githubConfigModel';
import { ConnectGithubBannerContainer } from './sourceControl/banner/connectGithubBannerContainer';
import { SourceControlNotifications } from './sourceControl/notifications/sourceControlNotifications';

/*
 * Props.
 */

interface DashboardPageProps {
  config?: GithubConfigModel;
}

/*
 * Style.
 */

const StyledPageContainerDiv = styled.div`
  display: flex;
  flex-flow: row;
  height: 100%;
  box-sizing: border-box;
  padding: 16px;
`;

const StyledPageSideContainerDiv = styled.div`
  display: flex;
  flex-flow: column;
  flex: 0 0 350px;
  margin-right: 16px;
`;

const StyledPageMainContainerDiv = styled.div`
  flex: 1;
`;

const GithubConfigHeaderDiv = styled.div`
  display: flex;
  flex-flow: row;
  font-size: ${fontSize.small};
  color: ${greys.shade40};
  padding: 0 8px;
`;

const GithubConfigUsernameDiv = styled.div`
  flex: 1;
  ${ellipsis()}
`;

const GithubConfigLastFetchDiv = styled.div`
  margin-left: 8px;
`;

/*
 * Component.
 */

export const DashboardPage: FC<DashboardPageProps> = (props) => {
  const { config } = props;

  return (
    <StyledPageContainerDiv>
      <StyledPageSideContainerDiv>
        {maybeRenderConfigHeader(config)}
        <SourceControlNotifications />
      </StyledPageSideContainerDiv>
      <StyledPageMainContainerDiv>
        {renderBanners()}
      </StyledPageMainContainerDiv>
    </StyledPageContainerDiv>
  );
};

/*
 * Helpers.
 */

function renderBanners() {
  return (
    <>
      <ConnectGithubBannerContainer />
    </>
  );
}

function maybeRenderConfigHeader(config?: GithubConfigModel) {
  if (!config)
    return null;
  return (
    <GithubConfigHeaderDiv>
      <GithubConfigUsernameDiv>
        {config.username}
      </GithubConfigUsernameDiv>
      <GithubConfigLastFetchDiv>
        Updated: {config.lastFetched ? maybeRenderDate(config.lastFetched) : 'awaiting first fetch'}
      </GithubConfigLastFetchDiv>
    </GithubConfigHeaderDiv>
  );
}

function maybeRenderDate(lastFetched?: Date) {
  if (typeof lastFetched === 'string')
    return null;
  return new Intl.DateTimeFormat('en-US', { dateStyle: 'short', timeStyle: 'short' }).format(lastFetched);
}
