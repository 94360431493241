import { connect } from "react-redux";

import { ChangePasswordAsyncAction } from "../../../store/data/users/userActions";
import { ThunkDispatch } from "../../../store/storeThunk";
import { ChangePasswordPage } from "./changePasswordPage";

/*
 * Redux.
 */

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  changePasswordAsync: (currentPassword: string, newPassword: string) => dispatch(ChangePasswordAsyncAction(currentPassword, newPassword)),
});

export const ChangePasswordPageContainer = connect(null, mapDispatchToProps)(ChangePasswordPage);
