/*
 * Props.
 */

export interface GithubConfigModel {
  createdAt: Date;
  lastFetched?: Date;
  username: string;
}

/*
 * Helpers.
 */

export function parseGithubConfig(resp: any): GithubConfigModel {
  return {
    createdAt: new Date(resp.created_at),
    lastFetched: resp.last_fetched ? new Date(resp.last_fetched) : undefined,
    username: resp.username,
  };
}
