import { fetchAsync, postAsync } from "../../../../app/http/http";
import { GithubConfigModel, parseGithubConfig } from "../../../../models/githubConfigModel";
import { NotificationTypesEnum } from "../../../../models/notificationModel";
import { disconnectGithubPusherChannel, setupGithubPusherChannel } from "../../../../pusher/githubPusherChannel";
import { ThunkAsyncAction } from "../../../storeThunk";
import { ActionWithPayload } from "../../dataActions";
import { DisplayNewNotificationAction } from "../../notifications/notificationActions";
import { userDataUpdatedAction } from "../../users/userActions";
import { getUser } from "../../users/userSelectors";

/*
 * Action types.
 */

export enum GithubActionsEnum {
  PUSHER = '@@data/source-control/github/PUSHER',
  PUSHER_CONNECTED = '@@data/source-control/github/PUSHER_CONNECTED',
  PUSHER_DISCONNECTED = '@@data/source-control/github/PUSHER_DISCONNECTED',

  CONFIG_LOADED = '@@data/source-control/github/CONFIG_LOADED',
}

/*
 * Actions Types.
 */

interface GithubPusherAction {
  type: GithubActionsEnum.PUSHER;
}

interface GithubPusherConnectedAction {
  type: GithubActionsEnum.PUSHER_CONNECTED;
}

interface GithubPusherDisconnectedAction {
  type: GithubActionsEnum.PUSHER_DISCONNECTED;
}

interface GithubConfigLoadedAction extends ActionWithPayload<{ config: GithubConfigModel }> {
  type: GithubActionsEnum.CONFIG_LOADED;
}

/*
 * Action Creators.
 */

export function githubPusherAction(): GithubPusherAction {
  return {
    type: GithubActionsEnum.PUSHER,
  };
}

export function githubPusherConnectedAction(): GithubPusherConnectedAction {
  return {
    type: GithubActionsEnum.PUSHER_CONNECTED,
  };
}

export function githubPusherDisconnectedAction(): GithubPusherDisconnectedAction {
  return {
    type: GithubActionsEnum.PUSHER_DISCONNECTED,
  };
}

export function githubConfigLoadedAction(config: GithubConfigModel): GithubConfigLoadedAction {
  return {
    type: GithubActionsEnum.CONFIG_LOADED,
    payload: { config },
  };
}

/*
 * Actions.
 */

export function connectToGithubPusherChannelAsyncAction(): ThunkAsyncAction<void> {
  return async (dispatch, getState) => {
    const user = getUser(getState());

    if (!user)
      return;

    try {
      await setupGithubPusherChannel(user.id);
      dispatch(githubPusherConnectedAction());
    } catch {
      dispatch(githubPusherConnectedAction());
    }
  };
}

export function disconnectToGithubPusherChannelAsyncAction(): ThunkAsyncAction<void> {
  return async (dispatch, getState) => {
    const user = getUser(getState());
    if (!user)
      return;

    disconnectGithubPusherChannel(user.id);
    dispatch(githubPusherDisconnectedAction());
  };
}

export function fetchGithubConfigAsyncAction(): ThunkAsyncAction<void> {
  return async (dispatch, getState) => {
    const user = getUser(getState());

    // If we do not have a user or we do not have github connected.
    if (!user || !user.services.github)
      return;

    try {
      const config = await fetchAsync<GithubConfigModel>(
        `/api/github/`,
        (resp) => parseGithubConfig(resp),
      );

      dispatch(githubConfigLoadedAction(config));
    } catch (err: any) {
      dispatch(DisplayNewNotificationAction(NotificationTypesEnum.ERROR, "Please connect your Github account."));
    }
  };
}

export function getOAuthUrlAndRedirectAsyncAction(): ThunkAsyncAction<void> {
  return async (dispatch, getState) => {
    const user = getUser(getState());

    // If we do not have a user or we already have github connected.
    if (!user || user.services.github)
      return;

    try {
      const url = await fetchAsync<string>(
        `/api/github/oauth/`,
        (resp) => resp.url,
      );
      window.location.href = url;
    } catch (err: any) {
      dispatch(DisplayNewNotificationAction(NotificationTypesEnum.ERROR, "There was an issue getting the github OAuth information. Please try again later."));
    }
  };
}

export function githubOAuthCallbackAsyncAction(code: string, state: string): ThunkAsyncAction<void> {
  return async (dispatch, getState) => {
    const user = getUser(getState());
    if (!user)
      return;

    try {
      const config = await postAsync<GithubConfigModel>(
        `/api/github/oauth`,
        { code, state },
        (resp) => parseGithubConfig(resp),
      );

      dispatch(githubConfigLoadedAction(config));
      dispatch(userDataUpdatedAction({
        ...user,
        services: {
          ...user.services,
          github: true,
        },
      }));
      dispatch(DisplayNewNotificationAction(NotificationTypesEnum.SUCCESS, "Connected Github!"));
    } catch (err: any) {
      dispatch(DisplayNewNotificationAction(NotificationTypesEnum.ERROR, "There was an issue authorizing with Github."));
    }
  };
}

/*
 * Union type.
 */

export type GithubAction = GithubPusherAction | GithubPusherConnectedAction | GithubPusherDisconnectedAction | GithubConfigLoadedAction;
