import { connect } from "react-redux";

import { UserLogoutAction } from "../../store/data/users/userActions";
import { getUser } from "../../store/data/users/userSelectors";
import { StoreState } from "../../store/storeState";
import { ThunkDispatch } from "../../store/storeThunk";
import { Toolbar } from "./toolbar";

/*
 * Redux.
 */

const mapStateToProps = (state: StoreState) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  logoutAsync: () => dispatch(UserLogoutAction()),
});

export const ToolbarContainer = connect(mapStateToProps, mapDispatchToProps)(Toolbar);
