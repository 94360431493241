import { connect } from "react-redux";

import { getOAuthUrlAndRedirectAsyncAction } from "../../../../../store/data/sourceControl/github/githubActions";
import { getUser } from "../../../../../store/data/users/userSelectors";
import { StoreState } from "../../../../../store/storeState";
import { ThunkDispatch } from "../../../../../store/storeThunk";
import { ConnectGithubBanner } from "./connectGithubBanner";

/*
 * Redux.
 */

const mapStateToProps = (state: StoreState) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  getOAuthUrlAndRedirectAsync: () => dispatch(getOAuthUrlAndRedirectAsyncAction()),
});

export const ConnectGithubBannerContainer = connect(mapStateToProps, mapDispatchToProps)(ConnectGithubBanner);
