import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { maxNonFullPageWidth } from '../../../helpers/styleHelpers';
import { Button, ButtonTypesEnum } from '../../components/button/button';
import { PageHeader } from '../../components/header/pageHeader';
import { PasswordInput } from '../../components/input/passwordInput';

/*
 * Props.
 */

interface ChangePasswordPageProps {
  changePasswordAsync: (currentPassword: string, newPassword: string) => Promise<void>;
}

/*
 * Style.
 */

const StyledChangePasswordPageContainerDiv = styled.div`
  display: flex;
  flex-flow: column;
  max-width: ${maxNonFullPageWidth}px;
  margin: auto;
  padding: 32px 32px 0 32px;
`;

const StyledPasswordInput = styled(PasswordInput)`
  margin: 16px 0 0 0;
  max-width: 400px;
`;

const StyledActionsContainerDiv = styled.div`
  display: flex;
  flex-flow: row;
  gap: 8px;
  margin-top: 48px;
`;

/*
 * Component.
 */

export const ChangePasswordPage: FC<ChangePasswordPageProps> = (props) => {
  const { changePasswordAsync } = props;
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [canChangePassword, setCanChangePassword] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setCanChangePassword(Boolean(currentPassword) && Boolean(newPassword) && newPassword === confirmNewPassword);
  }, [currentPassword, newPassword, confirmNewPassword]);

  const onChangePasswordClick = async () => {
    if (!canChangePassword)
      return;
    await changePasswordAsync(currentPassword, newPassword);
    history.push('/user');
  };

  return (
    <StyledChangePasswordPageContainerDiv>
      <PageHeader
        title="Change Password"
        breadcrumbs={[
          {
            link: '/',
            name: 'Dashboard',
          },
          {
            link: '/user',
            name: 'Profile',
          },
        ]}
      />
      <br />
      <StyledPasswordInput placeholder="Enter password..." label="Current password" value={currentPassword} onChange={(val) => setCurrentPassword(val.trim())} />
      <br />
      <StyledPasswordInput placeholder="Enter new password..." label="New password" value={newPassword} onChange={(val) => setNewPassword(val.trim())} />
      <StyledPasswordInput placeholder="Confirm new password..." label="Confirm new password" value={confirmNewPassword} onChange={(val) => setConfirmNewPassword(val.trim())} />

      <StyledActionsContainerDiv>
        <Button onClick={() => history.push('/user')}>Cancel</Button>
        <Button type={ButtonTypesEnum.PRIMARY} isDisabled={!canChangePassword} onClick={onChangePasswordClick}>Change Password</Button>
      </StyledActionsContainerDiv>
    </StyledChangePasswordPageContainerDiv>
  );
};
