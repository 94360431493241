import React, {
  FC, RefObject, useRef, useState,
} from 'react';

import { Button, ButtonTypesEnum } from '../button/button';

export interface PopoverProps {
  anchor: RefObject<HTMLElement>;
  anchorWidth?: number;
  onRequestClose: () => void;
  tabIndex: number;
}

interface PopoverCoordinatorProps {
  renderButton: (isOpen: boolean) => React.ReactNode;
  renderModal: (props: PopoverProps) => React.ReactNode;
}

export const PopoverCoordinator: FC<PopoverCoordinatorProps> = (props) => {
  const { renderButton, renderModal, children } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const onRequestClose = () => {
    setIsModalOpen(false);
  };

  const render = () => renderModal({
    onRequestClose,
    anchor: buttonRef,
    anchorWidth: buttonRef.current?.offsetWidth,
    tabIndex: 0,
  });

  if (!buttonRef)
    return null;

  return (
    <>
      <Button
        type={ButtonTypesEnum.CUSTOM}
        buttonRef={buttonRef}
        onClick={(event) => {
          if (event.defaultPrevented)
            return;
          setIsModalOpen(true);
        }}
      >
        {renderButton(isModalOpen)}
      </Button>
      {isModalOpen && render()}
      {children}
    </>
  );
};
