export interface UserModel {
  id: string;
  email: string;
  displayName?: string;

  services: {
    github?: boolean;
  }
}

/*
 * Parsers.
 */

export function parseUser(src: any): UserModel {
  return {
    id: src.uuid,
    displayName: src.display_name,
    email: src.email,
    services: src.services ?? {},
  };
}
