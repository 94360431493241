import React, { FC } from 'react';

import { InputProps, InputWrapper } from './inputWrapper';

/*
 * Props.
 */

interface PasswordInputProps extends InputProps {
  className?: string;
  value: string;
  onChange: (val: string) => void;
}

/*
 * Component.
 */

export const PasswordInput: FC<PasswordInputProps> = (props) => {
  const {
    placeholder, value, className, label, onChange,
  } = props;
  return (
    <InputWrapper className={className} label={label}>
      <input value={value} type="password" placeholder={placeholder} onChange={(event) => onChange(event.target.value)} />
    </InputWrapper>
  );
};
