import { applyMiddleware, compose, createStore } from '@reduxjs/toolkit';
import _ from 'lodash';
import { devToolsEnhancer } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { buildStoreReducer } from './storeReducer';
import { StoreState } from './storeState';

/*
 * Types.
 */

export const store = buildAppStore();

export type AppDispatch = typeof store.dispatch;

/*
 * Functions.
 */

export function buildAppStore() {
  const reducer = buildStoreReducer();
  const initialState = localStorage ? JSON.parse(localStorage.getItem('state') ?? '{}') : {};
  const isLocal = window.location.hostname === 'localhost';

  delete initialState?.data?.notifications;

  if (initialState)
    storeBoot(initialState);

  const middleware = _([
    applyMiddleware(thunk),
    isLocal ? devToolsEnhancer({
      shouldRecordChanges: false,
    }) : undefined,
  ]).compact().value();

  return createStore(
    reducer,
    initialState,
    compose(
      ...middleware,
    ),
  );
}

function storeBoot(initialState: StoreState) {
  // No Boot Actions.
}
