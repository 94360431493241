import { batch } from 'react-redux';

import { fetchAsync, postAsync } from '../../../app/http/http';
import {
  parseRetro, parseRetroPublicId, RetroModel, RetroPublicIdModel,
} from '../../../models/retroModel';
import { parseRetroUsers, RetroUserModel } from '../../../models/retroUserModel';
import { ThunkAction } from '../../storeThunk';
import { ActionWithPayload } from '../dataActions';
import { retroUserUpdatedAction } from '../retroUsers/retroUserActions';

/*
 * Action types.
 */

export enum RetroActionsEnum {
  RETRO_UPDATED = '@@data/retros/RETRO_UPDATED',
  RETRO_CONNECTED = '@@data/retros/RETRO_CONNECTED',
  RETRO_DISCONNECTED = '@@data/retros/RETRO_DISCONNECTED',
}

/*
 * Action Types.
 */

interface RetroUpdatedActionPayload {
  retro: RetroModel;
}

interface RetroUpdatedAction extends ActionWithPayload<RetroUpdatedActionPayload> {
  type: RetroActionsEnum.RETRO_UPDATED;
}

interface RetroConnectedActionPayload {
  retroId: string;
  userId?: string;
}

interface RetroConnectedAction extends ActionWithPayload<RetroConnectedActionPayload> {
  type: RetroActionsEnum.RETRO_CONNECTED;
}

interface RetroDisconnectedActionPayload {
  retroId: string;
}

interface RetroDisconnectedAction extends ActionWithPayload<RetroDisconnectedActionPayload> {
  type: RetroActionsEnum.RETRO_DISCONNECTED;
}

/*
 * Action Creators.
 */

export function retroUpdatedAction(retro: RetroModel): RetroUpdatedAction {
  return {
    type: RetroActionsEnum.RETRO_UPDATED,
    payload: { retro },
  };
}

export function retroConnectedAction(retroId: string, userId?: string): RetroConnectedAction {
  return {
    type: RetroActionsEnum.RETRO_CONNECTED,
    payload: { retroId, userId },
  };
}

export function retroDisconnectedAction(retroId: string): RetroDisconnectedAction {
  return {
    type: RetroActionsEnum.RETRO_DISCONNECTED,
    payload: { retroId },
  };
}

/*
 * Actions.
 */

export async function createRetroAsyncAction(): Promise<string> {
  const response = await postAsync<RetroPublicIdModel>('/retro/create', {}, parseRetroPublicId);
  return response.publicId;
}

export function fetchRetroAsyncAction(retroId: string): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    const { retro, users } = await fetchAsync<{retro: RetroModel, users: ReadonlyArray<RetroUserModel>}>(
      `/retro/${retroId}`,
      (resp) => ({ retro: parseRetro(resp.retro), users: parseRetroUsers(resp.users) }),
    );

    if (retro)
      batch(() => {
        dispatch(retroUpdatedAction(retro));
        users.map((user) => dispatch(retroUserUpdatedAction(user)));
      });
  };
}

export function retroPusherConnectedAction(retroId: string, userId?: string): ThunkAction<void> {
  return (dispatch) => {
    dispatch(retroConnectedAction(retroId, userId));
  };
}

export function retroPusherDisconnectedAction(retroId: string): ThunkAction<void> {
  return (dispatch) => {
    dispatch(retroDisconnectedAction(retroId));
  };
}

/*
 * Union type.
 */

export type RetroAction = RetroUpdatedAction | RetroConnectedAction | RetroDisconnectedAction;
