import { RetroUserModel } from '../../../models/retroUserModel';
import { StoreAction } from '../../storeActions';
import { RetroUserActionsEnum } from './retroUserActions';

/*
 * Reducer.
 */

export function retroUsersReducer(state: ReadonlyArray<RetroUserModel> = [], action: StoreAction): ReadonlyArray<RetroUserModel> {
  switch (action.type) {
    case RetroUserActionsEnum.RETRO_USER_UPDATED:
      return [
        ...state,
        action.payload.user,
      ];
    default:
      return state;
  }
}
