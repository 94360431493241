import React, { FC } from "react";
import ReactDOM from "react-dom";

export const Portal: FC<any> = ({ children, className = 'root-portal', el = 'div' }) => {
  const [container] = React.useState(() =>
    // This will be executed only on the initial render
    // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    document.createElement(el));

  React.useEffect(() => {
    container.classList.add(className);
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ReactDOM.createPortal(children, container);
};
