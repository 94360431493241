import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  fontSize, greys, reds, shadow,
} from '../../helpers/styleHelpers';
import { UserModel } from '../../models/userModel';
import { Button, ButtonTypesEnum } from '../components/button/button';
import { PasswordInput } from '../components/input/passwordInput';
import { TextInput } from '../components/input/textInput';

/*
 * Props.
 */

interface CreatePageProps {
  user: UserModel | undefined;
  createAccountAsync: (email: string, password: string, displayName?: string) => Promise<void>;
}

/*
 * Style.
 */

const StyledCreateContainerDiv = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 400px;
  height: 575px;
  box-shadow: ${shadow.h2};
  background: ${greys.white};
`;

const StyledCreateHeaderDiv = styled.div`
  text-align: center;
  font-size: ${fontSize.large};
  font-weight: 600;
  padding: 20px 0;
`;

const StyledCreateContentDiv = styled.div`
  padding: 16px 32px;
  flex: 1;
`;

const StyledCreateActionsDiv = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const StyledButton = styled(Button)`
  padding: 6px 20px;

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

const StyledErrorMessageDiv = styled.div`
  padding-bottom: 12px;
  text-align: center;
  color: ${reds.default};

  &:empty {
    padding-top: 19px;
  }
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledTextInput = styled(TextInput)`
  margin-top: 12px;
`;

const StyledPasswordInput = styled(PasswordInput)`
  margin-top: 12px;
`;

/*
 * Component.
 */

export const CreatePage: FC<CreatePageProps> = (props) => {
  const { user, createAccountAsync } = props;
  const history = useHistory();

  const [isDisabled, setIsDisabled] = useState(true);
  const [error, setError] = useState();

  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    setIsDisabled(Boolean(!email || email.trim() !== confirmEmail.trim() || !password || password.trim() !== confirmPassword.trim()));
  }, [email, password, confirmEmail, confirmPassword]);

  useEffect(() => {
    if (user)
      history.push('/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateClick = async () => {
    setIsDisabled(true);
    setError(undefined);
    try {
      await createAccountAsync(email.trim(), password.trim(), displayName.trim());
      history.push(findNextUrl(history.location.search));
    } catch (err: any) {
      setError(err.message);
      setIsDisabled(false);
    }
  };

  return (
    <StyledCreateContainerDiv>
      <StyledCreateHeaderDiv>Create a new account</StyledCreateHeaderDiv>
      <StyledErrorMessageDiv>{error}</StyledErrorMessageDiv>
      <StyledCreateContentDiv>
        <TextInput value={displayName} placeholder="Enter display name..." label="Display name (optional)" onChange={(val) => setDisplayName(val)} />
        <br />
        <StyledTextInput value={email} placeholder="Enter email..." label="Email" onChange={(val) => setEmail(val)} />
        <StyledTextInput value={confirmEmail} placeholder="Confirm email..." label="Confirm email" onChange={(val) => setConfirmEmail(val)} />
        <br />
        <StyledPasswordInput value={password} placeholder="Enter password..." label="Password" onChange={(val) => setPassword(val)} />
        <StyledPasswordInput value={confirmPassword} placeholder="Confirm password..." label="Confirm password" onChange={(val) => setConfirmPassword(val)} />
      </StyledCreateContentDiv>
      <StyledCreateActionsDiv>
        <StyledButton type={ButtonTypesEnum.PRIMARY} isDisabled={isDisabled} onClick={onCreateClick}>
          Create
        </StyledButton>
        <StyledButton onClick={() => history.push('/')}>
          Back home
        </StyledButton>
      </StyledCreateActionsDiv>
    </StyledCreateContainerDiv>
  );
};

/*
 * Helpers.
 */

function findNextUrl(query: string) {
  const striped = query.slice(1);
  const split = striped.split('=');

  const indexOfNext = split.indexOf('next');
  if (indexOfNext === -1)
    return '/';
  const nextUrl = split[indexOfNext + 1];
  return nextUrl ? decodeURIComponent(nextUrl) : '/';
}
