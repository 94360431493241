import { EffectCallback, MouseEvent, useEffect } from "react";

export type Handler = () => void;
export type AsyncHandler = () => Promise<void>;
export type ClickHandler = (event: MouseEvent) => void;

export function useInitialEffect(effect: EffectCallback) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}
