import React, { FC } from 'react';
import styled from 'styled-components';

import { greys, shadow } from '../../../../../helpers/styleHelpers';

/*
 * Props.
 */

interface NotificationsProps {}

/*
 * Style.
 */

const StyledNotificationsContainerDiv = styled.div`
  border-radius: 8px;
  margin-top: 4px;
  box-shadow: ${shadow.h2};
  border: 1px solid ${greys.shade10};
  flex: 1;
  background: ${greys.white};
`;

/*
 * Component.
 */

export const SourceControlNotifications: FC<NotificationsProps> = (props) => <StyledNotificationsContainerDiv />;
