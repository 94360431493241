import React, { FC } from 'react';
import styled from 'styled-components';

/*
 * Style.
 */

const StyledUnderConstructionContainerDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100px;
  width: 300px;
  margin: auto;
  background: white;
  border-radius: 8px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 16px;
  display: flex;
  align-items: center;
  text-align: center; 
`;

const StyledUnderConstructionContentDiv = styled.div`
  text-align: center;
  width: 100%;
  line-height: 24px;
`;

/*
 * Component.
 */

export const UnderConstruction: FC = () => (
  <StyledUnderConstructionContainerDiv>
    <StyledUnderConstructionContentDiv>
      Currently giving my website a facelift...<br /> Check back later!
    </StyledUnderConstructionContentDiv>
  </StyledUnderConstructionContainerDiv>
);
