import { transparentize } from 'polished';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { greys } from '../../../helpers/styleHelpers';

/*
 * Props.
 */

export interface InputProps {
  placeholder?: string;
  className?: string;
  label?: string;
  isDisabled?: boolean;
}

/*
 * Style.
 */

interface StyledInputWrapperDivProps {
  $isDisabled?: boolean;
}

const StyledInputWrapperDiv = styled.div<StyledInputWrapperDivProps>`
  border: 1px solid ${greys.shade40};
  border-radius: 4px;
  padding: 8px;

  ${(p) => p.$isDisabled && css`
    background: ${transparentize(0.9, greys.shade60)};
  `}

  & > input {
    border: 0;
    outline: none;
    width: 100%;
    background: transparent;
  }
`;

const StyledLabelDiv = styled.div`
  font-size: 13px;
  font-weight: 400;
  margin: 0 0 4px 4px;
`;

/*
 * Component.
 */

export const InputWrapper: FC<InputProps> = (props) => {
  const {
    children, className, label, isDisabled,
  } = props;

  return (
    <div className={className}>
      {label && <StyledLabelDiv>{label}</StyledLabelDiv>}
      <StyledInputWrapperDiv $isDisabled={isDisabled}>{children}</StyledInputWrapperDiv>
    </div>
  );
};
