import React, { FC } from 'react';
import { connect } from "react-redux";

import { useInitialEffect } from '../../../helpers/reactHelpers';
import { GithubConfigModel } from '../../../models/githubConfigModel';
import { connectToGithubPusherChannelAsyncAction, disconnectToGithubPusherChannelAsyncAction, fetchGithubConfigAsyncAction } from '../../../store/data/sourceControl/github/githubActions';
import { getGithubConfig } from '../../../store/data/sourceControl/github/githubSelectors';
import { StoreState } from '../../../store/storeState';
import { ThunkDispatch } from "../../../store/storeThunk";
import { DashboardPage } from './dashboardPage';

/*
 * Props.
 */

interface RetroPageContainerStateProps {
  config?: GithubConfigModel;
}

interface RetroPageContainerDispatchProps {
  connectPusherAsync: () => Promise<void>;
  disconnectPusherAsync: () => Promise<void>;
  fetchGithubConfigAsync: () => Promise<void>;
}
type RetroPageContainerComponentProps = RetroPageContainerDispatchProps & RetroPageContainerStateProps;

/*
 * Component.
 */

export const DashboardPageContainerComponent: FC<RetroPageContainerComponentProps> = (props) => {
  const {
    config, connectPusherAsync, disconnectPusherAsync, fetchGithubConfigAsync,
  } = props;

  useInitialEffect(() => {
    connectPusherAsync();
    fetchGithubConfigAsync();

    return () => {
      disconnectPusherAsync();
    };
  });

  return <DashboardPage config={config} />;
};

/*
 * Redux.
 */

const mapStateToProps = (state: StoreState) => ({
  config: getGithubConfig(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  connectPusherAsync: () => dispatch(connectToGithubPusherChannelAsyncAction()),
  disconnectPusherAsync: () => dispatch(disconnectToGithubPusherChannelAsyncAction()),
  fetchGithubConfigAsync: () => dispatch(fetchGithubConfigAsyncAction()),
});

export const DashboardPageContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardPageContainerComponent);
