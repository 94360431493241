import { NotificationModel } from '../../../models/notificationModel';
import { StoreAction } from '../../storeActions';
import { NotificationActionsEnum } from './notificationActions';

/*
 * Reducer.
 */

export function notificationReducer(state: ReadonlyArray<NotificationModel> | undefined, action: StoreAction): ReadonlyArray<NotificationModel> | undefined {
  switch (action.type) {
    case NotificationActionsEnum.ADD:
      return [...(state ?? []), action.payload.notification];
    case NotificationActionsEnum.REMOVE:
      return (state ?? []).filter((notif) => notif.id !== action.payload.notificationId);

    default:
      return state;
  }
}
