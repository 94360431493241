import { UserModel } from '../../../models/userModel';
import { StoreAction } from '../../storeActions';
import { UserActionsEnum } from './userActions';

/*
 * Reducer.
 */

export function userReducer(state: UserModel | undefined, action: StoreAction): UserModel | undefined {
  switch (action.type) {
    case UserActionsEnum.LOGGED_IN:
    case UserActionsEnum.DATA_UPDATED:
      return action.payload.user;
    case UserActionsEnum.LOGGED_OUT:
      return undefined;

    default:
      return state;
  }
}
