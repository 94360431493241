import { PusherChannelsModel } from '../../../models/pusherChannelsModel';
import { PusherStatusesEnum } from '../../../pusher/pusher';
import { StoreAction } from '../../storeActions';
import { GithubActionsEnum } from '../sourceControl/github/githubActions';

/*
 * Reducer.
 */

export function pusherReducer(state: PusherChannelsModel | undefined, action: StoreAction): PusherChannelsModel | undefined {
  switch (action.type) {
    case GithubActionsEnum.PUSHER: {
      if (!state)
        return {
          github: PusherStatusesEnum.WAITING,
        };
      return {
        ...state,
        github: PusherStatusesEnum.WAITING,
      };
    }
    case GithubActionsEnum.PUSHER_CONNECTED: {
      if (!state)
        return {
          github: PusherStatusesEnum.CONNECTED,
        };
      return {
        ...state,
        github: PusherStatusesEnum.CONNECTED,
      };
    }
    case GithubActionsEnum.PUSHER_DISCONNECTED: {
      if (!state)
        return {
          github: PusherStatusesEnum.DISCONNECTED,
        };
      return {
        ...state,
        github: PusherStatusesEnum.DISCONNECTED,
      };
    }

    default:
      return state;
  }
}
