import axios, { Method } from "axios";

interface HttpRequestOptions {
  url: string;
  data?: any;
  method: Method;
}

const instance = axios.create();

instance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken)
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (res) => res,
  async (err) => {
    const originalConfig = err.config;

    if (originalConfig.url !== "/api/user/login" && err.response)
      // Access Token was expired
      // eslint-disable-next-line no-underscore-dangle
      if (err.response.status === 401 && !originalConfig._retry) {
        // eslint-disable-next-line no-underscore-dangle
        originalConfig._retry = true;

        try {
          const rs = await instance.post("/api/user/refresh/token", {
            token: localStorage.getItem('refreshToken'),
          });

          const { accessToken, refreshToken } = rs.data;
          localStorage.setItem('accessToken', accessToken);
          localStorage.setItem('refreshToken', refreshToken);

          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }

    return Promise.reject(err);
  },
);

/*
 * Helpers.
 */

export async function postAsync<R>(url: string, data?: any, parser?: (parse: any) => R): Promise<R> {
  return httpRequestAsync<R>({
    url,
    data,
    method: 'POST',
  }).then((response) => (parser ? parser(response) : response));
}

export async function fetchAsync<R>(url: string, parser?: (parse: any) => R): Promise<R> {
  return httpRequestAsync<R>({
    url,
    method: 'GET',
  }).then((response) => (parser ? parser(response) : response));
}

export async function httpRequestAsync<R>(options: HttpRequestOptions): Promise<R> {
  const { url, method, data } = options;

  try {
    const response = await instance.request({
      url,
      method,
      data,
    });
    return response.data;
  } catch (err: any) {
    throw new Error(err.response?.data?.message || err.message);
  }
}
