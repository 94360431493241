import { RetroUserModel } from "../../../models/retroUserModel";
import { ActionWithPayload } from "../dataActions";

/*
 * Action types.
 */

export enum RetroUserActionsEnum {
  RETRO_USER_UPDATED = '@@data/retro-users/RETRO_USER_UPDATED',
}

/*
 * Action Types.
 */

interface RetroUserUpdatedActionPayload {
  user: RetroUserModel;
}

interface RetroUserUpdatedAction extends ActionWithPayload<RetroUserUpdatedActionPayload> {
  type: RetroUserActionsEnum.RETRO_USER_UPDATED;
}

/*
 * Action Creators.
 */

export function retroUserUpdatedAction(user: RetroUserModel): RetroUserUpdatedAction {
  return {
    type: RetroUserActionsEnum.RETRO_USER_UPDATED,
    payload: { user },
  };
}

/*
 * Union type.
 */

export type RetroUserAction = RetroUserUpdatedAction;
