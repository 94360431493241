import Pusher from "pusher-js";

/*
 * Constants.
 */

export enum PusherStatusesEnum {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  WAITING = 'waiting'
}

const pusherKey = 'bd1f0e9daed44941d1d3';

export const pusher = new Pusher(pusherKey, {
  cluster: 'us3',
});

export enum PusherClientRetroEventsEnum {
  USER_UPDATED = 'client-retro-user-updated'
}
