/*
 * Constants.
 */

export enum RetroConnectionStatusesEnum {
  WAITING = 'waiting',
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected'
}

/*
 * Interfaces.
 */

export interface RetroModel {
  publicId: string;
  status: RetroConnectionStatusesEnum,
  createdAt: Date;
  deletedAt?: Date;
  name?: string;

  // The id for the user.
  userId?: string;
}

export interface RetroPublicIdModel {
  publicId: string;
}

/*
 * Helpers.
 */

export function parseRetro(resp: any): RetroModel {
  return {
    publicId: resp.public_id,
    status: RetroConnectionStatusesEnum.WAITING,
    createdAt: resp.created_at,
    deletedAt: resp.deleted_at,
    name: resp.name,
  };
}

export function parseRetroPublicId(resp: any): RetroPublicIdModel {
  return {
    publicId: resp.public_id,
  };
}
