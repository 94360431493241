import { connect } from "react-redux";

import { UserCreateAccountAsyncAction } from "../../store/data/users/userActions";
import { getUser } from "../../store/data/users/userSelectors";
import { StoreState } from "../../store/storeState";
import { ThunkDispatch } from "../../store/storeThunk";
import { CreatePage } from "./createPage";

/*
 * Redux.
 */

const mapStateToProps = (state: StoreState) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  createAccountAsync: (email: string, password: string, displayName?: string) => dispatch(UserCreateAccountAsyncAction(email, password, displayName)),
});

export const CreatePageContainer = connect(mapStateToProps, mapDispatchToProps)(CreatePage);
