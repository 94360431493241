import { connect } from "react-redux";

import { getNotifications } from "../../store/data/notifications/notificationSelectors";
import { StoreState } from "../../store/storeState";
import { Notifications } from "./notifications";

/*
 * Redux.
 */

const mapStateToProps = (state: StoreState) => ({
  notifications: getNotifications(state),
});

export const NotificationsContainer = connect(mapStateToProps)(Notifications);
