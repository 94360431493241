import { History } from 'history';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { ClickHandler } from '../../helpers/reactHelpers';
import { greys } from '../../helpers/styleHelpers';
import { UserModel } from '../../models/userModel';
import { Button, ButtonTypesEnum } from '../components/button/button';
import { Dropdown } from '../components/dropdown/Dropdown';
import { DropdownItem } from '../components/dropdown/DropdownItem';
import { PopoverCoordinator } from '../components/popover/popoverCoordinator';

/*
 * Props.
 */

interface ToolbarProps {
  className?: string;
  user: UserModel | undefined;
  logoutAsync: () => Promise<void>;
}

/*
 * Style.
 */

const StyledToolbarContainerDiv = styled.div`
  display: flex;
  flex-flow: row;
  background-color: ${greys.shade80};
  color: ${greys.white};
`;

const StyledMainContainerDiv = styled.div`
  flex: 1;
`;

const StyledUserContainerDiv = styled.div`
  padding: 11px;
`;

const StyledButton = styled(Button)`
  font-size: 16px;
  padding: 8px 12px;
`;

/*
 * Component.
 */

export const Toolbar: FC<ToolbarProps> = (props) => {
  const { className, user, logoutAsync } = props;
  const history = useHistory();

  const [isDisabled, setIsDisabled] = useState(false);

  const onLogoutClick = async () => {
    setIsDisabled(true);
    try {
      await logoutAsync();
      history.push('/');
    } catch (err: any) {
      console.error(err);
    }
    setIsDisabled(false);
  };

  return (
    <StyledToolbarContainerDiv className={className}>
      <StyledMainContainerDiv />
      <StyledUserContainerDiv>
        {renderUserButton(history, isDisabled, user, onLogoutClick)}
      </StyledUserContainerDiv>
    </StyledToolbarContainerDiv>
  );
};

/*
 * Helpers.
 */

function renderUserButton(history: History, isDisabled: boolean, user: UserModel | undefined, onLogoutClick: ClickHandler) {
  if (user)
    return (
      <PopoverCoordinator
        renderButton={(isOpen) => (
          <StyledButton type={ButtonTypesEnum.LINK} isDisabled={isDisabled} hasHover={isOpen} minWidth={25} maxWidth={200}>
            {user?.displayName || user?.email}
          </StyledButton>
        )}
        renderModal={(popoverProps) => (
          <Dropdown popoverProps={popoverProps} placement="bottom-end" width={200}>
            <DropdownItem onClick={() => history.push('/user')}>
              Profile
            </DropdownItem>
            <DropdownItem onClick={onLogoutClick}>
              Log out
            </DropdownItem>
          </Dropdown>
        )}
      />
    );

  return (
    <StyledButton type={ButtonTypesEnum.LINK} isDisabled={isDisabled} onClick={() => history.push('/login')}>
      Login
    </StyledButton>
  );
}
