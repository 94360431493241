import { NotificationModel, NotificationTypesEnum } from "../../../models/notificationModel";
import { ThunkAction } from "../../storeThunk";
import { ActionWithPayload } from "../dataActions";

// Temp way to track notification ids...
let notificationId = 1;

/*
 * Action types.
 */

export enum NotificationActionsEnum {
  ADD = '@@data/notifications/ADD',
  REMOVE = '@@data/notifications/REMOVE',
}

/*
 * Action Types.
 */

interface AddNotificationActionPayload {
  notification: NotificationModel;
}

interface AddNotificationAction extends ActionWithPayload<AddNotificationActionPayload> {
  type: NotificationActionsEnum.ADD;
}

interface RemoveNotificationActionPayload {
  notificationId: number;
}

interface RemoveNotificationAction extends ActionWithPayload<RemoveNotificationActionPayload> {
  type: NotificationActionsEnum.REMOVE;
}

/*
 * Action Creators.
 */

export function addNotificationAction(notification: NotificationModel): AddNotificationAction {
  return {
    type: NotificationActionsEnum.ADD,
    payload: { notification },
  };
}

export function removeNotificationAction(notificationId: number): RemoveNotificationAction {
  return {
    type: NotificationActionsEnum.REMOVE,
    payload: { notificationId },
  };
}

/*
 * Actions.
 */

export function DisplayNewNotificationAction(type: NotificationTypesEnum, message: string): ThunkAction<void> {
  return (dispatch) => {
    const id = ++notificationId;
    dispatch(addNotificationAction({
      id,
      type,
      message,
    }));

    setTimeout(() => {
      dispatch(removeNotificationAction(id));
    }, 3000);
  };
}

/*
 * Union type.
 */

export type NotificationAction = AddNotificationAction | RemoveNotificationAction;
