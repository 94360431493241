import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { fontSize, greys, maxNonFullPageWidth } from '../../helpers/styleHelpers';
import { Button, ButtonTypesEnum } from '../components/button/button';

/*
 * Style.
 */

const StyledInvalidRoutePageContainerDiv = styled.div`
  max-width: ${maxNonFullPageWidth}px;
  margin: auto;
  text-align: center;
  padding-top: 64px;
`;

const StyledHeaderH1 = styled.h1`
  font-size: ${fontSize.large};
`;

const StyledHeaderH2 = styled.h1`
  font-size: ${fontSize.normal};
  font-weight: 400;
  color: ${greys.shade80};
  margin-bottom: 32px;
`;

/*
 * Component.
 */

export const InvalidRoutePage: FC = () => {
  const history = useHistory();

  return (
    <StyledInvalidRoutePageContainerDiv>
      <StyledHeaderH1>Hmmm, not sure what you were looking for.</StyledHeaderH1>
      <StyledHeaderH2>Lets head on back home</StyledHeaderH2>
      <Button type={ButtonTypesEnum.PRIMARY} onClick={() => history.push('/')}>Head back Home</Button>
    </StyledInvalidRoutePageContainerDiv>
  );
};

