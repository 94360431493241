import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Handler } from '../../../helpers/reactHelpers';
import { maxNonFullPageWidth } from '../../../helpers/styleHelpers';
import { UserModel } from '../../../models/userModel';
import { Button, ButtonSizesEnum, ButtonTypesEnum } from '../../components/button/button';
import { Dialog } from '../../components/dialog/dialog';
import { DialogActions } from '../../components/dialog/dialogActions';
import { PageHeader } from '../../components/header/pageHeader';
import { TextInput } from '../../components/input/textInput';

/*
 * Props.
 */

interface UserPageProps {
  user: UserModel;
  updateUserAsync: (displayName?: string) => Promise<void>;
  deleteUserAsync: () => Promise<void>;
}

/*
 * Style.
 */

const StyledUserPageContainerDiv = styled.div`
  display: flex;
  flex-flow: column;
  max-width: ${maxNonFullPageWidth}px;
  margin: auto;
  padding: 32px 32px 0 32px;
`;

const StyledUserContentDiv = styled.div`
  padding: 32px 0;
`;

const StyledTextInput = styled(TextInput)`
  max-width: 400px;

  &:not(:first-child) {
    margin-top: 18px;
  }
`;

const StyledActionsContainerDiv = styled.div`
  display: flex;
  flex-flow: row;
  gap: 8px;
`;

/*
 * Component.
 */

export const UserPage: FC<UserPageProps> = (props) => {
  const { user, updateUserAsync, deleteUserAsync } = props;
  const [canUpdate, setCanUpdate] = useState(false);
  const [isShowingDeleteDialog, setIsShowingDeleteDialog] = useState(false);
  const [displayName, setDisplayName] = useState(user.displayName ?? '');
  const history = useHistory();

  useEffect(() => {
    setCanUpdate((user.displayName ?? '') !== displayName.trim());
  }, [displayName, user.displayName]);

  const onUpdateClick = async () => {
    if (!canUpdate)
      return;
    await updateUserAsync(displayName.trim());
    setCanUpdate(false);
  };

  const onDelete = async () => {
    await deleteUserAsync();
    history.push('/');
  };

  return (
    <StyledUserPageContainerDiv>
      <PageHeader
        title="Profile"
        breadcrumbs={[
          {
            link: '/',
            name: 'Dashboard',
          },
        ]}
        actions={(
          <>
            <Button
              size={ButtonSizesEnum.MEDIUM}
              type={ButtonTypesEnum.SECONDARY}
              onClick={() => history.push('/user/change-password')}
            >
              Change password
            </Button>
            <Button
              size={ButtonSizesEnum.MEDIUM}
              type={ButtonTypesEnum.DANGER}
              onClick={() => setIsShowingDeleteDialog(true)}
            >
              Delete account
            </Button>
          </>
        )}
      />
      <StyledUserContentDiv>
        <StyledTextInput label="Email" value={user.email} isDisabled />
        <StyledTextInput label="Display name" placeholder="Enter a display name..." value={displayName} onChange={(val) => setDisplayName(val)} />
      </StyledUserContentDiv>
      <StyledActionsContainerDiv>
        <Button onClick={() => history.push('/')}>Back home</Button>
        <Button type={ButtonTypesEnum.PRIMARY} isDisabled={!canUpdate} onClick={onUpdateClick}>Update</Button>
      </StyledActionsContainerDiv>
      {isShowingDeleteDialog && renderDeleteDialog(() => setIsShowingDeleteDialog(false), onDelete)}
    </StyledUserPageContainerDiv>
  );
};

/*
 * Helpers.
 */

function renderDeleteDialog(onCancel: Handler, onDelete: Handler) {
  return (
    <Dialog title="Delete your account?" width={500} height={225}>
      <p>Sorry to see you go! Any and all data that we have on you will be permanently deleted, this cannot be undone.</p>
      <p>This includes but is not limited to all github information, all notifications and any backups we have of your data.</p>
      <p>If you ever want to return, you will have to create a new account.</p>
      <DialogActions>
        <Button size={ButtonSizesEnum.MEDIUM} onClick={onCancel}>
          Cancel
        </Button>
        <Button size={ButtonSizesEnum.MEDIUM} type={ButtonTypesEnum.DANGER} onClick={onDelete}>
          Delete permanently
        </Button>
      </DialogActions>
    </Dialog>
  );
}
