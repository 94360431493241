import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import {
  greens,
  greys, reds, shadow,
} from '../../helpers/styleHelpers';
import { NotificationModel, NotificationTypesEnum } from '../../models/notificationModel';

/*
 * Props.
 */

interface NotificationProps {
  notification: NotificationModel;
}

/*
 * Style.
 */

interface StyledNotificationDivProps {
  $type: NotificationTypesEnum;
}

const StyledNotificationDiv = styled.div<StyledNotificationDivProps>`
  border-radius: 4px;
  padding: 8px 12px;
  box-shadow: ${shadow.h1};

  ${(p) => p.$type === NotificationTypesEnum.ERROR && css`
    color: ${greys.white};
    background: ${reds.default};
  `}
  ${(p) => p.$type === NotificationTypesEnum.SUCCESS && css`
    color: ${greys.white};
    background: ${greens.default};
  `}
`;

/*
 * Component.
 */

export const Notification: FC<NotificationProps> = (props) => {
  const { notification } = props;

  return (
    <StyledNotificationDiv $type={notification.type}>
      {notification.message}
    </StyledNotificationDiv>
  );
};
