export const maxNonFullPageWidth = 1000; // px

export const greys = {
  white: '#f9f9f9',
  shade80: '#3e3e3e',
  shade60: '#5b5b5b',
  shade40: '#a1a1a1',
  shade20: '#c4c4c4',
  shade10: '#eeeeee',
  black: '#212121',
};

export const browns = {
  lightest: '#F5E8C7',
  light: '#DEBA9D',
  dark: '#9E7777',
  darkest: '#6F4C5B',
};

export const blues = {
  default: '#4CA3DD',
};

export const reds = {
  default: '#CC0000',
};

export const greens = {
  default: '#55AE3A',
};

export const fontSize = {
  extraLarge: '32px',
  large: '20px',
  normal: '14px',
  small: '13px',
  extraSmall: '12px',
};

export const primaryColor = '#297F87';
export const primaryColorText = greys.white;

export const shadow = {
  h1: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;',
  h2: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;',
};

