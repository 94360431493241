import { transparentize } from 'polished';
import React, { FC } from 'react';
import { BsKanbanFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  fontSize, greys, maxNonFullPageWidth,
} from '../../helpers/styleHelpers';

/*
 * Props.
 */

interface HomePageProps {}

/*
 * Style.
 */

const StyledHomePageContainerDiv = styled.div`
  display: flex;
  flex-flow: row;
  max-width: ${maxNonFullPageWidth}px;
  margin: auto;
`;

const StyledHomePageContentDiv = styled.div`
  flex: 1;
`;

const StyledSidepanelTitleH1 = styled.h1`
  font-size: ${fontSize.large};
  margin: 0 0 4px 0;
`;

const StyledSidepanelContentDiv = styled.div`
  flex: 0 0 266px;
  padding: 8px;
`;

const StyledSidepanelQuickLinksContainerDiv = styled.div`
  display: flex;
  flex-flow: column;
  gap: 12px;
`;

const StyledQuickLinkDiv = styled.div`
  padding: 16px;
  border: 1px solid ${greys.shade20};
  color: ${greys.shade80};
  cursor: pointer;
  border-radius: 4px;
  line-height: 14px;
  display: flex;
  align-items: center;

  &:hover {
    background: ${transparentize(0.9, greys.shade80)}
  }
`;

const StyledBsKanbanFill = styled(BsKanbanFill)`
  margin-right: 8px;
`;

/*
 * Component.
 */

export const HomePage: FC<HomePageProps> = (props) => {
  const history = useHistory();

  return (
    <StyledHomePageContainerDiv>
      <StyledHomePageContentDiv />
    </StyledHomePageContainerDiv>
  );
};
