import { StoreAction } from '../storeActions';
import { DataState } from './dataState';
import { notificationReducer } from './notifications/notificationReducer';
import { retroReducer } from './retros/retroReducer';
import { retroUsersReducer } from './retroUsers/retroUserReducer';
import { githubConfigReducer } from './sourceControl/github/githubConfigReducer';
import { userReducer } from './users/userReducer';

export function dataReducer(state: DataState = {}, action: StoreAction): DataState {
  return {
    ...state,
    retros: retroReducer(state.retros, action),
    retroUsers: retroUsersReducer(state.retroUsers, action),
    user: userReducer(state.user, action),
    notifications: notificationReducer(state.notifications, action),
    githubConfig: githubConfigReducer(state.githubConfig, action),
  };
}
