import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { fontSize, greys } from '../../../helpers/styleHelpers';

/*
 * Props.
 */

interface PageHeaderProps {
  title: string;
  actions?: ReactNode;
  breadcrumbs?: {
    link: string;
    name: string;
  }[];
}

/*
 * Style.
 */

const StyledHeaderContainerDiv = styled.div`
  display: flex;
  flex-flow: row;
  width: 100%;
`;

const StyledDetailsContainerDiv = styled.div`
  flex: 1;
`;

const StyledHeaderDiv = styled.div`
  font-size: ${fontSize.extraLarge};
  font-weight: 600;

  &:first-child {
    padding-top: 14px;
  }
`;

const StyledActionsContainerDiv = styled.div`
  display: flex;
  flex-flow: row;
  gap: 8px;
  padding-left: 12px;
  padding-top: 16px;
`;

const StyledBreadcrumbsContainerDiv = styled.div`
  display: flex;
  flex-flow: row;
  gap: 4px;
  margin-bottom: 2px;
  font-size: 12px;
  user-select: none;
`;

const StyledBreadcrumbDiv = styled.div`
  color: ${greys.shade60};
  cursor: pointer;

  & > span {
    &:hover {
      text-decoration: underline; 
    }
  }
`;

/*
 * Component.
 */

export const PageHeader: FC<PageHeaderProps> = (props) => {
  const { title, actions, breadcrumbs } = props;
  const history = useHistory();

  return (
    <StyledHeaderContainerDiv>
      <StyledDetailsContainerDiv>
        {breadcrumbs && (
          <StyledBreadcrumbsContainerDiv>
            {breadcrumbs.map((breadcrumb) => (
              <StyledBreadcrumbDiv key={`${breadcrumb.link}`} onClick={() => history.push(breadcrumb.link)}>
                {'/ '}<span>{breadcrumb.name}</span>
              </StyledBreadcrumbDiv>
            ))}
          </StyledBreadcrumbsContainerDiv>
        )}
        <StyledHeaderDiv>{title}</StyledHeaderDiv>
      </StyledDetailsContainerDiv>
      <StyledActionsContainerDiv>
        {actions}
      </StyledActionsContainerDiv>
    </StyledHeaderContainerDiv>
  );
};
