import React, { FC } from 'react';

import { InputProps, InputWrapper } from './inputWrapper';

/*
 * Props.
 */

interface TextInputProps extends InputProps {
  value: string;
  isDisabled?: boolean;
  onChange?: (val: string) => void;
}

/*
 * Component.
 */

export const TextInput: FC<TextInputProps> = (props) => {
  const {
    placeholder, value, className, label, isDisabled, onChange,
  } = props;
  return (
    <InputWrapper className={className} label={label} isDisabled={isDisabled}>
      <input value={value} type="text" disabled={isDisabled} placeholder={placeholder} onChange={onChange ? (event) => onChange(event.target.value) : undefined} />
    </InputWrapper>
  );
};
