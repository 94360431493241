import { GithubConfigModel } from "../../../../models/githubConfigModel";
import { StoreAction } from "../../../storeActions";
import { GithubActionsEnum } from "./githubActions";

/*
 * Reducer.
 */

export function githubConfigReducer(state: GithubConfigModel | undefined, action: StoreAction): GithubConfigModel | undefined {
  switch (action.type) {
    case GithubActionsEnum.CONFIG_LOADED:
      return action.payload.config;

    default:
      return state;
  }
}
