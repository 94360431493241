import { connect } from "react-redux";

import { UserLoginAsyncAction } from "../../store/data/users/userActions";
import { getUser } from "../../store/data/users/userSelectors";
import { StoreState } from "../../store/storeState";
import { ThunkDispatch } from "../../store/storeThunk";
import { LoginPage } from "./loginPage";

/*
 * Redux.
 */

const mapStateToProps = (state: StoreState) => ({
  user: getUser(state),
});

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  loginAsync: (email: string, password: string) => dispatch(UserLoginAsyncAction(email, password)),
});

export const LoginPageContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage);
