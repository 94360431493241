export enum NotificationTypesEnum {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface NotificationModel {
  id: number;
  type: NotificationTypesEnum;
  message: string;
}
