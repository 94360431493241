import _ from 'lodash';
import React, { FC, useState } from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { useInitialEffect } from '../../../helpers/reactHelpers';
import { fontSize } from '../../../helpers/styleHelpers';
import { Button, ButtonTypesEnum } from '../../components/button/button';

/*
 * Props.
 */

interface GithubOAuthCallbackProps {
  githubOAuthCallbackAsync: (code: string, state: string) => Promise<void>;
}

/*
 * Style.
 */

const StyledContentDiv = styled.div`
  text-align: center;
  font-size: ${fontSize.large};
  padding: 64px 0;
`;

const StyledButtonContainerDiv = styled.div`
  margin-top: 32px;
`;

/*
 * Component.
 */

export const GithubOAuthCallback: FC<GithubOAuthCallbackProps> = (props) => {
  const { githubOAuthCallbackAsync } = props;
  const [isErred, setIsErred] = useState(false);
  const history = useHistory();

  useInitialEffect(() => {
    const handle = async () => {
      const resp = parseSearchString();
      if (resp.error) {
        setIsErred(true);
      } else {
        await githubOAuthCallbackAsync(resp.code, resp.state);
        history.push('/dashboard');
      }
    };
    handle();
  });

  if (isErred)
    return (
      <StyledContentDiv>
        It looks like something went wrong. Please try again later.
        <StyledButtonContainerDiv>
          <Button type={ButtonTypesEnum.PRIMARY} onClick={() => history.push('/dashboard')}>Back to dashboard</Button>
        </StyledButtonContainerDiv>
      </StyledContentDiv>
    );
  return (
    <StyledContentDiv>
      Verifying github authorization information... You will be redirected automatically.
    </StyledContentDiv>
  );
};

/*
 * Helpers.
 */

function parseSearchString() {
  const { search } = window.location;
  const splitSearch = search.slice(1).split('&');
  return _(splitSearch).reduce((values, val) => {
    const splitVal = val.split('=');
    return {
      ...values,
      [splitVal[0]]: splitVal[1],
    };
  }, {} as {[val: string]: string});
}
