import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { AsyncHandler } from '../../../../../helpers/reactHelpers';
import { greys, primaryColor, shadow } from '../../../../../helpers/styleHelpers';
import { UserModel } from '../../../../../models/userModel';
import { Button, ButtonSizesEnum, ButtonTypesEnum } from '../../../../components/button/button';

/*
 * Props.
 */

interface ConnectGithubBannerProps {
  user?: UserModel;
  getOAuthUrlAndRedirectAsync: AsyncHandler;
}

/*
 * Style.
 */

const StyledBannerDiv = styled.div`
  display: flex;
  flex-flow: row;
  border-radius: 8px;
  background: ${greys.shade10};
  border: 1px solid ${primaryColor};
  margin-bottom: 16px;
  padding: 16px 24px;
  box-shadow: ${shadow.h1};
`;

const StyledBannerTextDiv = styled.div`
  flex: 1;
  padding-right: 16px;
`;

const StyledButton = styled(Button)`
  margin: -9px -16px -9px 0;
`;

/*
 * Component.
 */

export const ConnectGithubBanner: FC<ConnectGithubBannerProps> = (props) => {
  const { user, getOAuthUrlAndRedirectAsync } = props;

  const [isFetching, setIsFetching] = useState(false);

  const onClick = async () => {
    setIsFetching(true);
    await getOAuthUrlAndRedirectAsync();
    setIsFetching(false);
  };

  if (!user || user.services.github)
    return null;

  return (
    <StyledBannerDiv>
      <StyledBannerTextDiv>
        Connect your Github account to start using the developer center!
      </StyledBannerTextDiv>
      <StyledButton type={ButtonTypesEnum.PRIMARY} size={ButtonSizesEnum.MEDIUM} isDisabled={isFetching} onClick={onClick}>
        Connect Github
      </StyledButton>
    </StyledBannerDiv>
  );
};
