import React, { FC } from 'react';
import styled from 'styled-components';

/*
 * Style.
 */

const StyledDialogActionsContainerDiv = styled.div`
  display: flex;
  flex-flow: row;
  gap: 8px;
  margin-top: 24px;
`;

const StyledSpacerDiv = styled.div`
  flex: 1;
`;

/*
 * Component.
 */

export const DialogActions: FC = ({ children }) => (
  <StyledDialogActionsContainerDiv><StyledSpacerDiv />{children}</StyledDialogActionsContainerDiv>
);
