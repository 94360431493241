import { fetchAsync, postAsync } from "../../../app/http/http";
import { NotificationTypesEnum } from "../../../models/notificationModel";
import { parseUser, UserModel } from "../../../models/userModel";
import { ThunkAction } from "../../storeThunk";
import { ActionWithPayload } from "../dataActions";
import { DisplayNewNotificationAction } from "../notifications/notificationActions";

/*
 * Action types.
 */

export enum UserActionsEnum {
  LOGGED_IN = '@@data/users/LOGGED_IN',
  DATA_UPDATED = '@@data/users/DATA_UPDATED',
  LOGGED_OUT = '@@data/users/LOGGED_OUT',
}

/*
 * Action Types.
 */

interface UserLoggedInActionPayload {
  user: UserModel;
}

interface UserLoggedInAction extends ActionWithPayload<UserLoggedInActionPayload> {
  type: UserActionsEnum.LOGGED_IN;
}

interface UserLoggedOutAction {
  type: UserActionsEnum.LOGGED_OUT;
}

interface UserDataUpdatedActionPayload {
  user: UserModel;
}

interface UserDataUpdatedAction extends ActionWithPayload<UserDataUpdatedActionPayload> {
  type: UserActionsEnum.DATA_UPDATED;
}

/*
 * Action Creators.
 */

export function userLoggedInAction(user: UserModel): UserLoggedInAction {
  return {
    type: UserActionsEnum.LOGGED_IN,
    payload: { user },
  };
}

export function userLoggedOutAction(): UserLoggedOutAction {
  return {
    type: UserActionsEnum.LOGGED_OUT,
  };
}

export function userDataUpdatedAction(user: UserModel): UserDataUpdatedAction {
  return {
    type: UserActionsEnum.DATA_UPDATED,
    payload: { user },
  };
}

/*
 * Actions.
 */

export function UserLoginAsyncAction(email: string, password: string): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    const { accessToken, refreshToken, user } = await postAsync<{accessToken: string, refreshToken: string, user: UserModel}>(
      `/api/user/login`,
      { email, password },
      (resp) => ({ accessToken: resp.accessToken, refreshToken: resp.refreshToken, user: parseUser(resp.user) }),
    );

    dispatch(userLoggedInAction(user));
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };
}

export function UserCreateAccountAsyncAction(email: string, password: string, displayName?: string): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    const { accessToken, refreshToken, user } = await postAsync<{accessToken: string, refreshToken: string, user: UserModel}>(
      `/api/user/create`,
      { email, password, displayName },
      (resp) => ({ accessToken: resp.accessToken, refreshToken: resp.refreshToken, user: parseUser(resp.user) }),
    );

    dispatch(userLoggedInAction(user));
    dispatch(DisplayNewNotificationAction(NotificationTypesEnum.SUCCESS, "Created your account!"));
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
  };
}

export function UserLogoutAction(): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    await postAsync(`/api/user/logout`);

    dispatch(userLoggedOutAction());
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };
}

export function FetchUserDataAsyncAction(): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    const user = await fetchAsync(`/api/user/`, (resp) => parseUser(resp));

    dispatch(userDataUpdatedAction(user));
  };
}

export function ChangePasswordAsyncAction(currentPassword: string, newPassword: string): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    try {
      await postAsync(
        `/api/user/change-password`,
        { currentPassword, newPassword },
      );
      dispatch(DisplayNewNotificationAction(NotificationTypesEnum.SUCCESS, "Updated your password!"));
    } catch {
      dispatch(DisplayNewNotificationAction(NotificationTypesEnum.ERROR, "Unable to update your password. Make sure everything is correct."));
    }
  };
}

export function UpdateUserAsyncAction(displayName?: string): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    try {
      const user = await postAsync<UserModel>(
        `/api/user/update`,
        { displayName },
        (resp) => parseUser(resp),
      );
      dispatch(userLoggedInAction(user));
      dispatch(DisplayNewNotificationAction(NotificationTypesEnum.SUCCESS, "Updated information!"));
    } catch (err: any) {
      dispatch(DisplayNewNotificationAction(NotificationTypesEnum.ERROR, err.message));
    }
  };
}

export function DeleteUserAsyncAction(): ThunkAction<Promise<void>> {
  return async (dispatch) => {
    await postAsync(`/api/user/delete`);

    dispatch(userLoggedOutAction());
    dispatch(DisplayNewNotificationAction(NotificationTypesEnum.SUCCESS, "Deleted your account."));
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };
}

/*
 * Union type.
 */

export type UserAction = UserLoggedInAction | UserLoggedOutAction | UserDataUpdatedAction;
