import { RetroConnectionStatusesEnum } from '../../../models/retroModel';
import { StoreAction } from '../../storeActions';
import { RetroDataModel } from '../dataState';
import { RetroActionsEnum } from './retroActions';

/*
 * Reducer.
 */

export function retroReducer(state: RetroDataModel = {}, action: StoreAction): RetroDataModel {
  switch (action.type) {
    case RetroActionsEnum.RETRO_UPDATED:
      return {
        ...state,
        [action.payload.retro.publicId]: action.payload.retro,
      };
    case RetroActionsEnum.RETRO_CONNECTED:
      return {
        ...state,
        [action.payload.retroId]: {
          ...state[action.payload.retroId],
          status: RetroConnectionStatusesEnum.CONNECTED,
          userId: action.payload.userId ?? state[action.payload.retroId].userId,
        },
      };
    case RetroActionsEnum.RETRO_DISCONNECTED:
      return {
        ...state,
        [action.payload.retroId]: {
          ...state[action.payload.retroId],
          status: RetroConnectionStatusesEnum.DISCONNECTED,
        },
      };

    default:
      return state;
  }
}
