import { Reducer } from '@reduxjs/toolkit';

import { dataReducer } from './data/dataReducer';
import { pusherReducer } from './data/pusher/pusherReducer';
import { StoreAction } from './storeActions';
import { StoreState } from './storeState';

export function buildStoreReducer(): Reducer<StoreState, StoreAction> {
  return (prevState, action) => {
    const nextState: StoreState = {
      data: dataReducer(prevState?.data, action),
      pusherChannels: pusherReducer(prevState?.pusherChannels, action),
    };

    if (localStorage)
      localStorage.setItem('state', JSON.stringify(nextState));

    return nextState;
  };
}
