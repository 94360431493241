import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  fontSize, greys, reds, shadow,
} from '../../helpers/styleHelpers';
import { UserModel } from '../../models/userModel';
import { Button, ButtonTypesEnum } from '../components/button/button';
import { PasswordInput } from '../components/input/passwordInput';
import { TextInput } from '../components/input/textInput';

/*
 * Props.
 */

interface LoginPageProps {
  user: UserModel | undefined;
  loginAsync: (email: string, password: string) => Promise<void>;
}

/*
 * Style.
 */

const StyledLoginContainerDiv = styled.div`
  display: flex;
  flex-flow: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 400px;
  height: 300px;
  box-shadow: ${shadow.h2};
  background: ${greys.white};
`;

const StyledLoginHeaderDiv = styled.div`
  text-align: center;
  font-size: ${fontSize.large};
  font-weight: 600;
  padding: 20px 0;
`;

const StyledLoginContentDiv = styled.div`
  padding: 16px 32px;
  flex: 1;
`;

const StyledLoginActionsDiv = styled.div`
  text-align: center;
  padding-bottom: 20px;
`;

const StyledButton = styled(Button)`
  padding: 6px 20px;

  &:not(:first-child) {
    margin-left: 8px;
  }
`;

const StyledErrorMessageDiv = styled.div`
  padding-bottom: 12px;
  text-align: center;
  color: ${reds.default};

  &:empty {
    padding-top: 19px;
  }
  &::first-letter {
    text-transform: uppercase;
  }
`;

const StyledPasswordInput = styled(PasswordInput)`
  margin-top: 12px;
`;

/*
 * Component.
 */

export const LoginPage: FC<LoginPageProps> = (props) => {
  const { user, loginAsync } = props;
  const history = useHistory();

  const [isDisabled, setIsDisabled] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState();

  useEffect(() => {
    setIsDisabled(email.trim() === '' || password.trim() === '');
  }, [email, password]);

  useEffect(() => {
    if (user)
      history.push('/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoginClick = async () => {
    setIsDisabled(true);
    setError(undefined);
    try {
      await loginAsync(email.trim(), password.trim());
      history.push(findNextUrl(history.location.search));
    } catch (err: any) {
      setError(err.message);
      setIsDisabled(false);
    }
  };

  return (
    <StyledLoginContainerDiv>
      <StyledLoginHeaderDiv>Woodhousemckenzie Login</StyledLoginHeaderDiv>
      <StyledErrorMessageDiv>{error}</StyledErrorMessageDiv>
      <StyledLoginContentDiv>
        <TextInput value={email} placeholder="Email" onChange={(val) => setEmail(val)} />
        <StyledPasswordInput value={password} placeholder="Password" onChange={(val) => setPassword(val)} />
      </StyledLoginContentDiv>
      <StyledLoginActionsDiv>
        <StyledButton type={ButtonTypesEnum.PRIMARY} isDisabled={isDisabled} onClick={onLoginClick}>
          Login
        </StyledButton>
        <StyledButton onClick={() => history.push('/')}>
          Back home
        </StyledButton>
      </StyledLoginActionsDiv>
    </StyledLoginContainerDiv>
  );
};

/*
 * Helpers.
 */

function findNextUrl(query: string) {
  const striped = query.slice(1);
  const split = striped.split('=');

  const indexOfNext = split.indexOf('next');
  if (indexOfNext === -1)
    return '/';
  const nextUrl = split[indexOfNext + 1];
  return nextUrl ? decodeURIComponent(nextUrl) : '/';
}
