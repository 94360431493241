import { transparentize } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';

import { greys, shadow } from '../../../helpers/styleHelpers';
import { Portal } from '../portal/portal';

/*
 * Props.
 */

interface DialogProps {
  width: number;
  height: number;
  title: string;
}

/*
 * Style.
 */

const DialogContainerDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${transparentize(0.5, greys.shade60)};
`;

interface DialogDivProps {
  $width: number;
  $height: number;
}

const DialogDiv = styled.div<DialogDivProps>`
  display: flex;
  flex-flow: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border-radius: 12px;
  background: ${greys.white};
  padding: 24px;
  box-shadow: ${shadow.h2};

  width: ${(p) => p.$width}px;
  height: ${(p) => p.$height}px;
`;

const StyledDialogTitleDiv = styled.div`
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
`;

const StyledDialogContentDiv = styled.div`
  flex: 1;

  & > p {
    margin: 0;
    line-height: 20px;

    &:not(:first-child),
    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

/*
 * Component.
 */

export const Dialog: FC<DialogProps> = (props) => {
  const {
    width, height, title, children,
  } = props;
  return (
    <Portal>
      <DialogContainerDiv>
        <DialogDiv $width={width} $height={height}>
          <StyledDialogTitleDiv>{title}</StyledDialogTitleDiv>
          <StyledDialogContentDiv>{children}</StyledDialogContentDiv>
        </DialogDiv>
      </DialogContainerDiv>
    </Portal>
  );
};
