
import { pusher } from "./pusher";

/*
 * Helpers.
 */

export async function setupGithubPusherChannel(userId: string) {
  // We already are subscribed to this pusher channel
  const existingChannel = pusher.allChannels().find((channel) => channel.name === buildChannelName(userId));
  if (existingChannel)
    return;

  const accessToken = localStorage.getItem('accessToken');
  pusher.config!.auth = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const channel = pusher.subscribe(buildChannelName(userId));
  if (!channel.subscribed && !channel.subscriptionPending)
    throw new Error('Failed to connect');

  // Connected to pusher channel.
  channel.bind('pusher:subscription_succeeded', (data: any) => {
    // dispatch(retroPusherConnectedAction(retroId, data.myID));
  });

  // A new user was added.
  channel.bind('pusher:member_added', (data: any) => {
    console.log(data);
  });
}

export function disconnectGithubPusherChannel(userId: string) {
  const channel = pusher.allChannels().find((channel) => channel.name === buildChannelName(userId));
  if (channel)
    channel.disconnect();
}

function buildChannelName(retroId: string) {
  return `presence-github-${retroId}`;
}
