import { transparentize } from 'polished';
import React, { FC } from 'react';
import styled from 'styled-components';

import { ClickHandler } from '../../../helpers/reactHelpers';
import { greys } from '../../../helpers/styleHelpers';

/*
 * Props.
 */

interface DropdownItemProps {
  onClick?: ClickHandler;
}

/*
 * Style.
 */

const StyledDropdownItemContainerDiv = styled.div`
  padding: 14px 20px;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: ${transparentize(0.95, greys.shade60)};
  }
`;

/*
 * Component.
 */

export const DropdownItem: FC<DropdownItemProps> = (props) => {
  const { children, onClick } = props;

  return (
    <StyledDropdownItemContainerDiv onClick={onClick}>
      {children}
    </StyledDropdownItemContainerDiv>
  );
};
