import React, { FC } from 'react';
import styled from 'styled-components';

import { NotificationModel } from '../../models/notificationModel';
import { Notification } from './notification';

/*
 * Props.
 */

interface NotificationsProps {
  notifications?: ReadonlyArray<NotificationModel>;
}

/*
 * Style.
 */

const StyledNotificationsContainerDiv = styled.div`
  display: flex;
  flex-flow: column;
  gap: 8px;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 300px;
  padding: 16px;
`;

/*
 * Component.
 */

export const Notifications: FC<NotificationsProps> = (props) => {
  const { notifications } = props;

  return (
    <StyledNotificationsContainerDiv>
      {notifications?.map((notification) => <Notification key={notification.id} notification={notification} />)}
    </StyledNotificationsContainerDiv>
  );
};
