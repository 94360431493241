import { connect } from "react-redux";

import { githubOAuthCallbackAsyncAction } from "../../../store/data/sourceControl/github/githubActions";
import { ThunkDispatch } from "../../../store/storeThunk";
import { GithubOAuthCallback } from "./githubOAuthCallback";

/*
 * Redux.
 */

const mapDispatchToProps = (dispatch: ThunkDispatch) => ({
  githubOAuthCallbackAsync: (code: string, state: string) => dispatch(githubOAuthCallbackAsyncAction(code, state)),
});

export const GithubOAuthCallbackContainer = connect(null, mapDispatchToProps)(GithubOAuthCallback);
