import { Placement } from '@popperjs/core';
import { transparentize } from 'polished';
import React, { FC, useRef } from 'react';
import { Popper } from 'react-popper';
import styled, { css } from 'styled-components';

import { greys, shadow } from '../../../helpers/styleHelpers';
import { PopoverProps } from '../popover/popoverCoordinator';
import { Portal } from '../portal/portal';

/*
 * Props.
 */

interface DropdownProps {
  popoverProps: PopoverProps;
  placement?: Placement;
  width?: number;
  hasBackground?: boolean;
}

/*
 * Style.
 */

interface StyledDropdownDivProps {
  $hasBackground?: boolean;
}

const StyledDropdownContainerDiv = styled.div<StyledDropdownDivProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 10000000;

  ${(p) => p.$hasBackground && css`
    background: ${transparentize(0.9, greys.shade60)};
  `}
`;

interface StyledDropdownDivProps {
  $width?: number;
}

const StyledDropdownDiv = styled.div<StyledDropdownDivProps>`
  border-radius: 4px;
  background: ${greys.white};
  overflow: hidden;
  box-shadow: ${shadow.h1};
  color: ${greys.black};
  padding: 8px 0;

  ${(p) => p.$width && css`
    width: ${p.$width}px;
  `}
`;

/*
 * Component.
 */

export const Dropdown: FC<DropdownProps> = (props) => {
  const {
    popoverProps, placement = 'bottom-start', children, width, hasBackground,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  if (!popoverProps.anchor.current)
    return null;

  return (
    <Portal>
      <StyledDropdownContainerDiv
        ref={containerRef}
        onClick={popoverProps.onRequestClose}
        $hasBackground={hasBackground}
      >
        <Popper
          referenceElement={popoverProps.anchor.current}
          placement={placement}
          modifiers={[
            {
              name: 'preventOverflow',
              enabled: false,
              options: {
                padding: 8,
                boundary: containerRef.current as any,
              },
            },
          ]}
        >
          {({ ref, style }) => (
            <StyledDropdownDiv
              ref={ref}
              style={{ ...style }}
              data-placement={placement}
              $width={width}
            >
              {children}
            </StyledDropdownDiv>
          )}
        </Popper>
      </StyledDropdownContainerDiv>
    </Portal>
  );
};
